import React, {Fragment} from "react";
import styled from "@emotion/styled";
import xmark from "assets/img/Background.jpg";
import {ReactComponent as XLogo} from "assets/img/TAB_landing_logo.svg";
const Main = styled.div`
	background-image: url(${xmark});
	background-repeat: no-repeat;
	background-color: var(--tabx-neutrals-off-white);
	background-size: 100%;
	min-height: 780px;
	height: -webkit-fill-available;
	display: flex;
	flex-direction: column;

	.banner-wrapper {
		display: flex;
		flex-direction: column;
		gap: 12px;
		margin-top: 24px;
		margin-bottom: 33px;
	}
	@media (max-width: 640px) {
		background-size: initial;
		min-height: unset;
		background-position: bottom -100px right 50%;
		.banner-wrapper {
			gap: 14px;
			padding: 0px 20px;
		}
	}
`;

const LoginH2 = styled.h2`
	color: var(--TAB-Masterbrand-Primary-TAB-Night-60);
	text-align: center;
	font-size: 22px;
	font-style: normal;
	font-weight: 600;
	font-family: GraphikCondensed;
	line-height: 120%;
	max-width: 600px;
	margin: 0 auto;
`;

const Banner = styled.h1`
	color: var(--TAB-Masterbrand-Primary-TAB-Night-60);
	text-align: center;
	font-size: 50px;
	font-family: GraphikXCondensed;
	font-style: normal;
	font-weight: 700;
	line-height: 100%;
	text-transform: uppercase;
	margin: 0px auto;

	@media (max-width: 640px) {
		font-size: 30px;
		font-weight: 700;
		max-width: 335px;
		max-height: 62px;
		line-height: 100%;
		text-transform: uppercase;
	}
`;
const TabLogo = styled(XLogo)`
	margin: 40px auto 0px;
	width: 160px;

	@media (max-width: 900px) {
		width: 65px;
		height: auto;
		flex-shrink: 0;
		margin: 16px auto 0px;
	}
`;

const OffSeason: React.FC = () => {
	return (
		<Fragment>
			<Main>
				<TabLogo />
				<div className="banner-wrapper">
					<Banner className="banner">
						WELCOME TO <br />
						<span>TAB PICK & WIN</span>
					</Banner>
					<LoginH2>
						Thank you for playing TAB Pick & Win in 2024! <br />
						Pick & Win season is now closed as we prepare for the new season, we will
						keep you updated when TAB Pick & Win 2025 commences.
					</LoginH2>
				</div>
			</Main>
		</Fragment>
	);
};

export default OffSeason;
